import Vue from 'vue';

import commonScripts from './partials/common';
import validators from './partials/helpers/vueValidators';

commonScripts();

new Vue({
	el: '#signInForm',
	delimiters: ['${', '}'],
	mixins: [validators],
	data: {
		isSending: false,
		fields: {
			email: '',
			password: '',
		},
		errors: {
			email: false,
			password: false,
		},
		passwordVisible: false
	},
	methods: {
		showPassword() {
			this.passwordVisible = !this.passwordVisible;
		},

		send() {
			for (const key in this.fields) {
				this.errors[key] = (!this.fields[key] && this.errors[key] != null) ? 'To pole jest wymagane' : false;
			}

			for (const error in this.errors) {
				if (this.errors[error] != false) return;
			}

			this.isSending = true;

			// do dodania fetch
		}
	}
});
