import emailValidation from './emailValidation';

const validators = {
	methods: {
		checkLength(e, key, length = null ) {
			const value = e.target.value;
			const min = length ? length[0] : null;
			const max = length ? length[1] : null;

			switch (true) {
				case (value.length < min && value.length > 0):
				this.errors[key] = `To pole powinno zawierać ${min} znaków.`;
				break;
				case (value.length > max):
					this.errors[key] = `To pole powinno zawierać maksymalnie ${max} znaków.`;
					break;
				default:
					this.errors[key] = (!value && e.target.required) ? 'To pole nie może być puste.' : '';
					break;
			}

		},

		checkEmail(e, key) {
			const email = e.target.value;

			if (email) {
				this.errors[key] = (!emailValidation(email)) ? 'To nie jest adres e-mail.' : false;
			} else {
				this.checkLength(e, key);
			}
		},

		checkSelect(e, key) {
			this.errors[key] = (!this.fields[key]) ? 'To pole jest wymagane' : false;
		}
	}
};

export default validators;
